import { useState } from "react";
import useHttp from "../../../hooks/useHttp";
import {
  getAlertData,
  assignAlert,
  setAlertVerdict,
  getArchiveAlertData,
  checkOverride,
  overrideVerdict,
} from "../../../service/Endpoints";
import { useOktaAuth } from "@okta/okta-react";
import { AlertDataRespose } from "../../../dto/ResponeDTO.ts";
import { useEffect, useCallback, useContext } from "react";
import Loader from "../../utils/Loader.jsx";
import { OverlayContext } from "../../utils/OverLay.jsx";
import { useNotification } from "../../utils/Notification/NotificationContext.jsx";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  Grid,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { format } from "date-fns";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ConfirmationWindow } from "../../../service/Util.js";
const AlertCard = ({ data }) => {
  const {
    alertId,
    alertType,
    status,
    assignedTo,
    createdOn,
    assignedOn,
    assignmentHistory = [],
    verdict,
    verdictOn,
    country,
    accountId,
  } = data;

  const formatDate = (dateArray) => {
    return format(
      new Date(dateArray[0], dateArray[1] - 1, dateArray[2]),
      "yyyy-MM-dd"
    );
  };

  return (
    <div className="rounded-[5px] min-w-[30vw] border-gray-50  duration-300">
      <CardContent>
        <div className="text-gray-500 font-semibold text-[0.9vw] w-fit m-auto">
          <p>Details</p>
        </div>
        <Divider sx={{ my: 1 }} />

        <div className="flex flex-row gap-6">
          <div className="flex flex-col text-gray-400 font-semibold text-[0.7vw] w-[25%]">
            <p>Alert Id:</p>
            <p>Alert Type:</p>
            <p>Account:</p>
            <p>Country:</p>
            <p>Created On:</p>
            <p>Assignee:</p>
            <p>Assigned On:</p>
            <p>Status:</p>
            <p>Verdict:</p>
            <p>Verdict On:</p>
          </div>
          <div className="flex flex-col font-semibold text-[0.7vw] w-[100%] justify-items-start text-gray-700/70">
            <p className="text-[0.7vw]">{alertId}</p>
            <p>{alertType ? alertType : "N/A"}</p>
            <p className={`${accountId ? "" : "text-gray-300"}`}>
              {accountId ? accountId : "N/A"}
            </p>
            <p className={`${country ? "" : "text-gray-300"}`}>
              {country ? country : "N/A"}
            </p>
            <p>
              <EventIcon
                sx={{
                  fontSize: "18px",
                  marginRight: "5px",
                  marginBottom: "3px",
                }}
              />
              {createdOn ? formatDate(createdOn.split("-")) : "N/A"}
            </p>
            <p>
              <PersonIcon
                sx={{
                  fontSize: "18px",
                  marginRight: "5px",
                  marginBottom: "3px",
                }}
              />
              {assignedTo ? assignedTo : "Not Assigned"}
            </p>
            <p className={`${assignedOn ? "" : "text-gray-300"}`}>
              <EventIcon
                sx={{
                  fontSize: "18px",
                  marginRight: "5px",
                  marginBottom: "3px",
                }}
              />
              {assignedOn ? formatDate(assignedOn) : "N/A"}
            </p>
            <p>{status ? status : "N/A"}</p>
            <p
              className={`${
                verdict === "FRAUD"
                  ? "text-red-500"
                  : verdict
                  ? "text-green-500"
                  : "text-orange-300"
              }`}
            >
              {verdict ? verdict : "Pending"}
            </p>
            <p className={`${verdictOn ? "" : "text-gray-300"}`}>
              <EventIcon
                sx={{
                  fontSize: "18px",
                  marginRight: "5px",
                  marginBottom: "3px",
                }}
              />
              {verdictOn ? formatDate(verdictOn) : "N/A"}
            </p>
          </div>
          <div></div>
        </div>
        {!verdict && assignmentHistory != null && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="assignment-history-content"
              id="assignment-history-header"
            >
              <p className="text-gray-400 font-semibold text-[0.7vw]">
                Assignment History
              </p>
            </AccordionSummary>
            <AccordionDetails>
              {assignmentHistory != null &&
                assignmentHistory.map((history, index) => (
                  <Box key={index} sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          From:{" "}
                          <Chip
                            label={formatDate(history.from)}
                            size="small"
                            sx={{ backgroundColor: "#e0e0e0" }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">
                          To:{" "}
                          <Chip
                            label={formatDate(history.to)}
                            size="small"
                            sx={{ backgroundColor: "#e0e0e0" }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="body2" color="text.secondary">
                      Assigned to: {history.assignee}
                    </Typography>
                  </Box>
                ))}
            </AccordionDetails>
          </Accordion>
        )}
        <Divider sx={{ my: 2 }} />
      </CardContent>
    </div>
  );
};
const styleEditAlert = {
  container:
    "absolute transition delay-300 left-full top-0 rounded-2xl right-0 bottom-0 m-auto bg-white w-full h-[full] z-10",
  btn: "h-10 w-36 shadow-xl shadow-fuchsia-200 rounded-lg text-fuchsia-700 text-2xl transition ease-in-out delay-20  hover:scale-110 hover:duration-300 active:shadow-inner active:shadow-fuchsia-300",
};

const EditAlert = ({
  showEditAlert,
  onCloseEditAlertHandler,
  alertId,
  dataChangeHandler,
  type = "none",
}) => {
  //hooks
  const { isLoading, error, sendRequest: callApi } = useHttp();
  const { authState } = useOktaAuth();

  //context
  const overlayContext = useContext(OverlayContext);
  const { showNotification } = useNotification();



  /*States------------------------------------------------------------------------------------*/
  const [alertDetails, setAlertDetail] = useState(new AlertDataRespose());
  const [assignedToUser, setAssignedToUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [overRide, setOverRide] = useState(false);
  /*------------------------------------------------------------------------------------States*/

  /*Constants---------------------------------------------------------------------------------*/
  const logedInUserName = authState.idToken.claims.name;
  const logedInUserId = authState.idToken.claims.sub;
  let verdictValue = 0;
  /*---------------------------------------------------------------------------------Constants*/

  /*SideEffects-------------------------------------------------------------------------------*/
  useEffect(() => {
    setLoading(true);
    getAlertsFromAPI();
    setOverRide(false)
  }, [alertId]);

  useEffect(() => {
    setOverRide(false)
  }, []);

  useEffect(() => {
    setLoading(isLoading);
    
  }, [isLoading]);

  useEffect(() => {
    error && showNotification(error, "error");
  }, [error]);
  /*-------------------------------------------------------------------------------SideEffects*/

  /*ApiCall-----------------------------------------------------------------------------------*/
  const getAlertsFromAPI = useCallback(() => {
    setLoading(true);
    if (alertId) {
      let token = authState.accessToken.accessToken;
      if (type == "history") {
        callApi(
          { url: getArchiveAlertData(alertId) },
          (responseData) => {
            const response = new AlertDataRespose();
            response.applyData(responseData);
            setAlertDetail(response);
            if (authState.accessToken.claims.uid === response.assignedToId)
              setAssignedToUser(true);
            else setAssignedToUser(false);
          },
          token
        );
      } else {
        callApi(
          { url: getAlertData(alertId) },
          (responseData) => {
            const response = new AlertDataRespose();
            response.applyData(responseData);
            setAlertDetail(response);
            if (authState.accessToken.claims.uid === response.assignedToId)
              setAssignedToUser(true);
            else setAssignedToUser(false);
          },
          token
        );
      }
    }
    setLoading(false);
  });
  /*-----------------------------------------------------------------------------------ApiCall*/

  /*Functions---------------------------------------------------------------------------------*/

  const overRideVerdictHandler = () => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: checkOverride() },
      (responseData) => {
        setOverRide(true)
      },
      token
    );
  };


  const setVerdict = (verdict) => {
    console.log(verdict)
    if (
      authState.isAuthenticated &&
      (logedInUserId == alertDetails.assignedToId||overRide)
    ) {
      verdictValue = verdict == "Fraud" ? -1 : 1;
      overlayContext.setOverlayContent(() => (
        <ConfirmationWindow
          confirmationHandler={onVerdictConfirmationHandler}
          msg={`Set Alert ${alertDetails.alertId} as ${verdict}`}
        />
      ));
      overlayContext.setShowOverlay(true);
    } else {
      showNotification(
        "You are not authorized to perform this action",
        "error"
      );
    }
  };

  const onVerdictConfirmationHandler = (action) => {
    overlayContext.setShowOverlay(false);
    if (action === "confirm") {
      let token = authState.accessToken.accessToken;
      let url_str=""
      if(overRide){
        url_str=overrideVerdict(alertDetails.alertId, verdictValue)
      }
      else{
        url_str= setAlertVerdict(alertDetails.alertId, verdictValue)
      }
      callApi(
        { url: url_str },
        (responseData) => {
          verdictValue = 0;
          showNotification("Verdict Set Successfully", "success");
          onCloseEditAlertHandler();
          dataChangeHandler();
        },
        token
      );
    }
  };

  const onAssignmentConfirmationHandler = (action) => {
    overlayContext.setShowOverlay(false);
    if (action === "confirm") {
      let token = authState.accessToken.accessToken;
      callApi(
        { url: assignAlert(alertDetails.alertId) },
        (responseData) => {
          getAlertsFromAPI();
          showNotification("User Assigned", "success");
          dataChangeHandler();
          setAssignedToUser(true);
        },
        token
      );
    }
  };
  const assignAlertToUser = () => {
    let msg = `Are you sure you want to assign alert ${alertDetails.alertId} to ${logedInUserName}`;
    if (alertDetails.assignedTo) {
      msg = `Are you sure you want to assign alert ${alertDetails.alertId} to ${logedInUserId} from ${alertDetails.assignedTo}`;
    }
    overlayContext.setOverlayContent(() => (
      <ConfirmationWindow
        confirmationHandler={onAssignmentConfirmationHandler}
        msg={msg}
      />
    ));
    overlayContext.setShowOverlay(true);
  };
  /*---------------------------------------------------------------------------------Functions*/

  return (
    <div
      className={`z-[1000] ${styleEditAlert.container} ${
        showEditAlert && "-translate-x-full"
      }`}
    >
      <div className="font-bold text-2xl m-auto z-[1000]">
        <button
          onClick={onCloseEditAlertHandler}
          className="shadow 
                       h-10 w-10 
                       border-2 
                       rounded-xg 
                       text-slate-500 
                       text-2xl 
                       transition 
                       ease-in-out 
                       delay-20 
                       hover:text-red-700  
                       hover:duration-300 
                       active:shadow-inner 
                       active:shadow-white-300"
        >
          <vwc-icon type="close-small-solid"></vwc-icon>
        </button>

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex flex-row w-full justify-center p-5">
              <div className=" border-[3px] border-white border-gray-50 rounded-[7px] hover:border-gray-300 hover:border-[3px] duration-300">
                <AlertCard data={alertDetails} />
                {alertDetails.status != "COMPLETED" && (
                  <div className=" w-full flex flex-row justify-center">
                    {assignedToUser ? (
                      <div className="flex flex-row gap-6 w-fit justify-item-center">
                        <button
                          className="w-[8vw] text-center border-[2px] text-[0.6vw] px-2 rounded-[5px] shadow-red-300 hover:shadow-2xl duration-200 shadow-sm active:bg-red-200 text-red-500 font-semibold border-red-400"
                          onClick={() => {
                            setVerdict("Fraud");
                          }}
                        >
                          FRAUD
                        </button>

                        <button
                          className="w-[8vw] text-center border-[2px] text-[0.6vw] px-2 rounded-[5px] shadow-green-300 hover:shadow-2xl duration-200 shadow-sm active:bg-green-200 text-green-500 font-semibold border-green-400"
                          onClick={() => {
                            setVerdict("Not Fraud");
                          }}
                        >
                          NOT FRAUD
                        </button>
                      </div>
                    ) : (
                      <button
                        className="w-[8vw] text-center border-[2px] text-[0.6vw] px-2 rounded-[5px] shadow-blue-300 hover:shadow-2xl duration-200 shadow-sm active:bg-blue-200 text-blue-500 font-semibold border-blue-400"
                        onClick={() => {
                          assignAlertToUser(alertDetails.alertId);
                        }}
                      >
                        Assign To Me
                      </button>
                    )}
                  </div>
                )}
                {alertDetails.status == "COMPLETED" && (
                  <div className=" w-full flex flex-row justify-center">
                    {overRide ? (
                      <div className="flex flex-row gap-6 w-fit justify-item-center">
                        <button
                          className="w-[8vw] text-center border-[2px] text-[0.6vw] px-2 rounded-[5px] shadow-red-300 hover:shadow-2xl duration-200 shadow-sm active:bg-red-200 text-red-500 font-semibold border-red-400"
                          onClick={() => {
                            setVerdict("Fraud");
                          }}
                        >
                          FRAUD
                        </button>

                        <button
                          className="w-[8vw] text-center border-[2px] text-[0.6vw] px-2 rounded-[5px] shadow-green-300 hover:shadow-2xl duration-200 shadow-sm active:bg-green-200 text-green-500 font-semibold border-green-400"
                          onClick={() => {
                            setVerdict("Not Fraud");
                          }}
                        >
                          NOT FRAUD
                        </button>
                      </div>
                    ) : (
                      <button
                        className="w-[8vw] text-center border-[2px] text-[0.6vw] px-2 rounded-[5px] shadow-blue-300 hover:shadow-2xl duration-200 shadow-sm active:bg-blue-200 text-blue-500 font-semibold border-blue-400"
                        onClick={overRideVerdictHandler}
                      >
                        Change Verdict
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div
                id="style-scroll-bar"
                className="min-w-[600px] overflow-y-auto h-[80vh] text-lg font-normal shadow-sm bg-neutral-50 mx-2 p-[10px] w-full border-[3px] shadow-sm border-gray-50 rounded-[7px] hover:border-gray-300 duration-300"
              >
                {!alertId || !alertDetails || !alertDetails.details ? (
                  <div>No Data Found</div>
                ) : (
                  <Box
                    sx={{
                      fontSize: "0.870rem",
                      lineHeight: 1.5,
                      color: "text.secondary",
                    }}
                    dangerouslySetInnerHTML={{ __html: alertDetails.details }}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditAlert;
