import { Divider } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNotification } from "../../../utils/Notification/NotificationContext.jsx";
import { RoleRequest } from "../../../../dto/RequestDTO.ts";
import { OverlayContext } from "../../../utils/OverLay.jsx";
import { isObjectInArray } from "../../../../service/Util.js";

export default function UpdateRolePermForm({ availablePermission, onModifyRolePermission, existingPermission }) {
  /*States---------------------------------------------------------------------------------*/
  const [permQuery, setPermQuery] = useState("");
  const [filteredPerm, setFilteredPerm] = useState(availablePermission);
  const [selectedPerm, setSelectedPerm] = useState();
  const [removePerm, setRemovePerm] = useState();
  const [displayAvailPermList, setDisplayAvailPermList] = useState(availablePermission)
  const [displaySelectedPermList, setDisplaySelectedPermList] = useState(existingPermission)
  const [addPermList, setAddPermList] = useState([]);
  const [removePermList, setRemovePermList] = useState([]);
  /*---------------------------------------------------------------------------------States*/
  /*Context---------------------------------------------------------------------------------*/
  const { showNotification } = useNotification();
  /*---------------------------------------------------------------------------------Context*/
  /*Function-------------------------------------------------------------------------------*/
  const onConfirmation = () =>{
    onModifyRolePermission(addPermList,removePermList)
  };
  const onCancel = () => {
    onModifyRolePermission();
  };

  
  /*-------------------------------------------------------------------------------Function*/

  /*SideEffects----------------------------------------------------------------------------*/
  useEffect(() => {
    setFilteredPerm((prev) => [
      ...displayAvailPermList.filter((perm) =>
        perm.name.toLowerCase().includes(permQuery.toLowerCase())
      ),
    ]);
  }, [permQuery,displayAvailPermList]);
  
  useEffect(() => {
    if (selectedPerm) {
      setDisplaySelectedPermList(prev=>[...prev,selectedPerm])
      setDisplayAvailPermList(prev=>[...prev.filter(perm=>perm.id!=selectedPerm.id)])
      if(isObjectInArray(removePermList,selectedPerm)){
        setRemovePermList(prev=>[...prev.filter(perm=>perm.id!=selectedPerm.id)])
      }
      else{
        setAddPermList(prev=>[...prev,selectedPerm])
      }
      setSelectedPerm()
    }
  }, [selectedPerm]);

  useEffect(() => {
    if (removePerm) {
      setDisplayAvailPermList(prev=>[...prev,removePerm])
      setDisplaySelectedPermList(prev=>[...prev.filter(perm=>perm.id!=removePerm.id)])
      if(isObjectInArray(addPermList,removePerm)){
        setAddPermList(prev=>[...prev.filter(perm=>perm.id!=removePerm.id)])
      }
      else{
        setRemovePermList(prev=>[...prev,removePerm])
      }
      setRemovePerm()
    }
  }, [removePerm]);
  /*----------------------------------------------------------------------------SideEffects*/
  return (
    <div className="flex flex-row">
      <div className="flex flex-row p-2">
        <div className="h-[30vh] w-[20vw] p-5  border-[1px] rounded-l-xl ml-2">
          <div className="text-blue-500 font-semibold text-[0.6vw] w-fit m-auto">
            <p>Available Permissions</p>
          </div>
          <Divider sx={{ my: 1 }} />
          <div className="flex items-center border rounded-[4px] shadow-sm overflow-hidden">
            <div className="px-3 text-gray-500">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="px-4 py-2 w-80 outline-none text-[0.7vw] h-[2vh]"
              placeholder="Search permissions"
              value={permQuery}
              onChange={(e) => setPermQuery(e.target.value)}
            />
          </div>
          <div className="p-2 overflow-y-auto h-[18vh] flex flex-col gap-4 ">
            {filteredPerm.map((perm) => (
              <div
                onClick={() => setSelectedPerm(perm)}
                className={`bg-white w-full  px-5 py-2 rounded-[20px] shadow-md  hover:scale-[1.02] duration-200 cursor-pointer active:scale-[0.95] text-[0.7vw] bg-gray-200/40 flex flex-row justify-between`}
              >
                <div className="flex flex-col">
                  <p className="font-medium text-[0.5vw] text-gray-600">
                    {perm.name}
                    {isObjectInArray(removePermList,perm)&&<span className="text-red-500 px-2 bg-red-200 rounded-xl ml-1">Removed</span>}
                  </p>
                  <p className="text-[0.5vw] text-red-400">
                    {perm.description}
                  </p>
                </div>
                <p className="text-blue-500 text-[0.6vw]">Add</p>
              </div>
            ))}
          </div>
        </div>
        <div className="h-[30vh] w-[20vw] p-5 rounded-r-xl border-[1px]">
          <div className="text-green-500 font-semibold text-[0.6vw] w-fit m-auto">
            <p>
              Selected Permissions<span className="text-red-500">*</span>
            </p>
          </div>
          <Divider sx={{ my: 1 }} />
          <div className="p-2 overflow-y-auto h-[18vh] flex flex-col gap-4">
            {displaySelectedPermList.map((perm) => (
              <div
                onClick={() => setRemovePerm(perm)}
                className={`bg-white w-full px-5 py-2  p-1 rounded-[20px] shadow-md  hover:scale-[1.02] duration-200 cursor-pointer active:scale-[0.95] text-[0.7vw] bg-green-50 flex flex-row justify-between`}
              >
                <div className="flex flex-col">
                  <p className="font-medium text-[0.5vw]">{perm.name} {isObjectInArray(addPermList,perm)&&<span className="text-blue-500 px-2 bg-blue-200 rounded-xl">Added</span>}</p>
                  <p className="text-[0.5vw] text-red-400">
                    {perm.description}
                  </p>
                </div>
                <p className="text-red-500 text-[0.6vw]">Remove</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-5 m-auto ml-10">
          <button
            className="w-[5vw] border-[1px] rounded-xl border-green-400 text-green-500 hover:scale-[1.1] duration-200 active:scale-[0.9]"
            onClick={onConfirmation}
          >
            Confirm
          </button>
          <button className="w-[5vw] border-[1px] rounded-xl border-red-400 text-red-500 hover:scale-[1.1] duration-200 active:scale-[0.9]"
          onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
