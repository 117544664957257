import { Divider } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNotification } from "../../../utils/Notification/NotificationContext";
import { RoleRequest } from "../../../../dto/RequestDTO.ts";
import { OverlayContext } from "../../../utils/OverLay.jsx";

export default function CreateRoleForm({ allPermission, onCreateRole }) {
  /*States---------------------------------------------------------------------------------*/
  const [permQuery, setPermQuery] = useState("");
  const [filteredPerm, setFilteredPerm] = useState([]);
  const [selectedPerm, setSelectedPerm] = useState();
  const [removePerm, setRemovePerm] = useState();
  const [availablePerm, setAvailablePerm] = useState([]);
  const [selectedPermList, setSelectedPermList] = useState([]);
  const [roleName, setRoleName] = useState();
  const [roleDescription, setRoleDescription] = useState();
  /*---------------------------------------------------------------------------------States*/
  /*Context---------------------------------------------------------------------------------*/
  const { showNotification } = useNotification();
  /*---------------------------------------------------------------------------------Context*/
  /*Function-------------------------------------------------------------------------------*/
  const onConfirmation = () => {
    if (!roleName) {
      showNotification("Role name is required", "error");
      return;
    }
    if (!description) {
      showNotification("Description name is required", "error");
      return;
    }
    if (selectedPermList.length == 0) {
      showNotification("Atleast one permission should be selected", "error");
      return;
    }
    const roleRequest = new RoleRequest();
    roleRequest.roleName = roleName;
    roleRequest.description = roleDescription;
    roleRequest.permissionList = selectedPermList.map((perm) => perm.name);
    onCreateRole(roleRequest);
  };
  const onCancel = () => {
    onCreateRole();
  };
  /*-------------------------------------------------------------------------------Function*/

  /*SideEffects----------------------------------------------------------------------------*/
  useEffect(() => {
    setFilteredPerm((prev) => [
      ...availablePerm.filter((perm) =>
        perm.name.toLowerCase().includes(permQuery.toLowerCase())
      ),
    ]);
  }, [permQuery]);
  useEffect(() => {
    setAvailablePerm(allPermission.permissionList);
    setFilteredPerm(allPermission.permissionList);
    setSelectedPermList([]);
  }, [allPermission]);

  useEffect(() => {
    if (selectedPerm) {
      let temp = availablePerm.filter((perm) => perm.name != selectedPerm.name);
      setAvailablePerm(temp);
      setFilteredPerm(temp);
      setSelectedPermList((prev) => [...prev, selectedPerm]);
      setSelectedPerm();
    }
  }, [selectedPerm]);

  useEffect(() => {
    if (removePerm) {
      let temp = [...availablePerm, removePerm];
      setAvailablePerm(temp);
      setFilteredPerm(temp);
      setSelectedPermList((prev) => [
        ...prev.filter((perm) => perm.name != removePerm.name),
      ]);
      setRemovePerm();
    }
  }, [removePerm]);
  /*----------------------------------------------------------------------------SideEffects*/
  return (
    <div className="flex flex-row">
      <div className="flex flex-col w-[20vw] gap-10 p-2 mr-10">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Role Name
            <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter role name"
            required
          />
        </div>
        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
            <span className="text-red-500">*</span>
          </label>
          <textarea
            id="description"
            name="description"
            value={roleDescription}
            onChange={(e) => setRoleDescription(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter a description"
            rows="4"
            required
          ></textarea>
        </div>
      </div>
      <div className="flex flex-row p-2">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Permissions:
        </label>
        <div className="h-[30vh] w-[20vw] p-5  border-[1px] rounded-l-xl ml-2">
          <div className="text-blue-500 font-semibold text-[0.6vw] w-fit m-auto">
            <p>Available Permissions</p>
          </div>
          <Divider sx={{ my: 1 }} />
          <div className="flex items-center border rounded-[4px] shadow-sm overflow-hidden">
            <div className="px-3 text-gray-500">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="px-4 py-2 w-80 outline-none text-[0.7vw] h-[2vh]"
              placeholder="Search permissions"
              value={permQuery}
              onChange={(e) => setPermQuery(e.target.value)}
            />
          </div>
          <div className="p-2 overflow-y-auto h-[18vh] flex flex-col gap-4 ">
            {filteredPerm.map((perm) => (
              <div
                onClick={() => setSelectedPerm(perm)}
                className={`bg-white w-full  px-5 py-2 rounded-[20px] shadow-md  hover:scale-[1.02] duration-200 cursor-pointer active:scale-[0.95] text-[0.7vw] bg-gray-200/40 flex flex-row justify-between`}
              >
                <div className="flex flex-col">
                  <p className="font-medium text-[0.5vw] text-gray-600">
                    {perm.name}
                  </p>
                  <p className="text-[0.5vw] text-red-400">
                    {perm.description}
                  </p>
                </div>
                <p className="text-blue-500 text-[0.6vw]">Select</p>
              </div>
            ))}
          </div>
        </div>
        <div className="h-[30vh] w-[20vw] p-5 rounded-r-xl border-[1px]">
          <div className="text-green-500 font-semibold text-[0.6vw] w-fit m-auto">
            <p>
              Selected Permissions<span className="text-red-500">*</span>
            </p>
          </div>
          <Divider sx={{ my: 1 }} />
          <div className="p-2 overflow-y-auto h-[18vh] flex flex-col gap-4">
            {selectedPermList.map((perm) => (
              <div
                onClick={() => setRemovePerm(perm)}
                className={`bg-white w-full px-5 py-2  p-1 rounded-[20px] shadow-md  hover:scale-[1.02] duration-200 cursor-pointer active:scale-[0.95] text-[0.7vw] bg-green-50 flex flex-row justify-between`}
              >
                <div className="flex flex-col">
                  <p className="font-medium text-[0.5vw]">{perm.name}</p>
                  <p className="text-[0.5vw] text-red-400">
                    {perm.description}
                  </p>
                </div>
                <p className="text-red-500 text-[0.6vw]">Remove</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-5 m-auto ml-10">
          <button
            className="w-[5vw] border-[1px] rounded-xl border-green-400 text-green-500 hover:scale-[1.1] duration-200 active:scale-[0.9]"
            onClick={onConfirmation}
          >
            Confirm
          </button>
          <button className="w-[5vw] border-[1px] rounded-xl border-red-400 text-red-500 hover:scale-[1.1] duration-200 active:scale-[0.9]"
          onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
