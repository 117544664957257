export class ResponsePermissions {
  permissionList: Array<Permission> = [];
  applyData(json: Object) {
    Object.assign(this, json);
  }
}
 
 class Permission{
  id: string;
  name: string;
  description:string;
}

