import {
  Autocomplete,
  TextField,
  Button,
  Checkbox,
  Divider,
  InputAdornment,
  IconButton,
  Chip,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import Popover from "@mui/material/Popover";
import CheckIcon from "@mui/icons-material/Check";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  getAlertAccId,
  getAlertCountries,
  getAlertTypes,
  getUserFilter,
  getUsersList,
  setGlobalFilter,
  setUserFilter,
} from "../../../service/Endpoints.js";
import { UserDetailResponse } from "../../../dto/ResponeDTO.ts";
import { useOktaAuth } from "@okta/okta-react";
import useHttp from "../../../hooks/useHttp.js";
import { PageLoaderContext } from "../../utils/PageLoader";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNotification } from "../../utils/Notification/NotificationContext.jsx";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { OverlayContext } from "../../utils/OverLay.jsx";
let usersListArchive = [];
const FilterComponent = ({ onSearch, notFilter }) => {
  //State---------------------------------------------------------------------------------
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [alertId, setAlertId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterName, setFilterName] = useState("");
  const [alertTypeOption, setAlertTypes] = useState([]);
  const [countryOption, setCountryTypes] = useState([]);
  const [accountOption, setAccountTypes] = useState([]);
  const [usersListOption, setUsersList] = useState([]);
  const [savedFilter, setSavedFilter] = useState();
  const [filterAction, setFilterAction] = useState();
  const [selectedAccIds, setSelectedAccIds] = useState([]);
  const [verdict, setVerdict] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  //---------------------------------------------------------------------------------State
  //hooks---------------------------------------------------------------------------------
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();
  const { showNotification } = useNotification();
  //---------------------------------------------------------------------------------hooks

  //Context---------------------------------------------------------------------------------
  const pageLoaderContext = useContext(PageLoaderContext);
  const overlayContext = useContext(OverlayContext);
  //---------------------------------------------------------------------------------Context

  //API Calls---------------------------------------------------------------------------------
  const getUserListFromAPI = React.useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getUsersList() },
      (responseData) => {
        const userDetailResp = new UserDetailResponse();
        userDetailResp.applyData(responseData);
        let data = userDetailResp.users.map((user) => {
          return { key: user.userId, value: user.name };
        });
        usersListArchive = data;
        setUsersList(data);
      },
      token
    );
  });
  const getAlertTypesFromApi = React.useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAlertTypes() },
      (responseData) => {
        setAlertTypes(responseData);
      },
      token
    );
  });

  const getAlertAccFromApi = React.useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAlertAccId() },
      (responseData) => {
        setAccountTypes(responseData);
      },
      token
    );
  });

  const getAlertCountriesFromApi = React.useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAlertCountries() },
      (responseData) => {
        setCountryTypes(responseData);
      },
      token
    );
  });

  const getUserFilterFromApi = React.useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getUserFilter() },
      (response) => {
        setSavedFilter(response);
      },
      token
    );
  });
  const saveUserFilter = React.useCallback((pagableFilter, type) => {
    let token = authState.accessToken.accessToken;
    pagableFilter["filterName"] = filterName;
    callApi(
      {
        url: type == "global" ? setGlobalFilter() : setUserFilter(),
        body: pagableFilter,
        headers: { "Content-Type": "application/json" },
        method: "POST",
      },
      (response) => {
        showNotification("Filter Saved");
        getUserFilterFromApi();
        setFilterAction()
      },
      token
    );
  });
  //---------------------------------------------------------------------------------API Calls

  //Constants---------------------------------------------------------------------------------
  const statusOptions = ["New", "Assigned"];
  const checkedParam = {
    icon: <CheckBoxOutlineBlankIcon fontSize="small" />,
    checkedIcon: <CheckBoxIcon fontSize="small" />,
  };
  const filterTextParam = {
    variant: "outlined",
    sx: { width: { xs: "100%", md: 200 }, borderRadius: "10px" },
    InputLabelProps: { style: { fontSize: "12px" } },
  };
  const filterAutoParam = {
    multiple: true,
    size: "small",
    limitTags: 0,
    disableCloseOnSelect: true,
    getOptionLabel: (option) => option,
  };
  const filterBtnParam = {
    sx: {
      height: "30px",
      marginLeft: "1px",
      color: "#000000",
      "&:hover": { backgroundColor: "#000000", color: "white" },
    },
  };
  //---------------------------------------------------------------------------------Constants

  //SideEffects---------------------------------------------------------------------------------
  useEffect(() => {
    pageLoaderContext.showLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    getAlertTypesFromApi();
    getAlertAccFromApi();
    getAlertCountriesFromApi();
    getUserFilterFromApi();
    if (usersListArchive.length > 0) {
      setUsersList(usersListArchive);
    } else {
      getUserListFromAPI();
    }
  }, []);
  useEffect(() => {
    error && showNotification(error, "error");
  }, [error]);
  //---------------------------------------------------------------------------------SideEffects

  //Functions---------------------------------------------------------------------------------
  const createNewPagableFilter = () => {
    const pagableFilter = new PagableFilter();
    selectedTypes.length > 0 &&
      pagableFilter.addFilter("alertType", selectedTypes.toString(), "in");
    selectedStatus.length > 0 &&
      pagableFilter.addFilter("status", selectedStatus.toString(), "in");
    selectedAssignees.length > 0 &&
      pagableFilter.addFilter(
        "userId",
        usersListOption
          .filter((user) => selectedAssignees.includes(user.value))
          .map((user) => user.key)
          .toString(),
        "in"
      );

    selectedAccIds.length > 0 &&
      pagableFilter.addFilter("accountId", selectedAccIds.toString(), "in");
    alertId && pagableFilter.addFilter("alertId", alertId);
    verdict != "" && pagableFilter.addFilter("verdict", verdict, "in");
    return pagableFilter;
  };
  const onSearchHandler = () => {
    onSearch(createNewPagableFilter());
  };
  const onClearSearchHandler = () => {
    const pagableFilter = new PagableFilter();
    setSelectedTypes([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setSelectedAccIds([]);
    setSelectedCountry([]);
    //setVerdict("");
    setAlertId("");
    onSearch(pagableFilter);
  };

  const onPopOverHandler = (event, filterAction) => {
    setAnchorEl(event.currentTarget);
    setFilterAction(filterAction);
    setFilterName("");
  };

  const onConfirmFilterSaveHandler = (type) => {
    if (filterName.trim() !== "") {
      const pagableFilter = createNewPagableFilter();
      saveUserFilter(pagableFilter, type);
    }
    setAnchorEl(null);
  };

  const handleSaveFilterPopClose = () => {
    setAnchorEl(null);
    setFilterName("");
  };

  const handleUserFilterSelected = (value, type) => {
    setSelectedTypes([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setAlertId("");
    const selectedUsrFilter =
      type == "local"
        ? savedFilter.userfilters[value]
        : savedFilter.globalfilters[value];
    selectedUsrFilter.forEach((filter) => {
      const valueArray = filter.value.split(",").map((item) => item.trim());
      if (filter.field == "alertType" && !notFilter.includes(1)) {
        setSelectedTypes(valueArray);
      }
      if (filter.field == "accountId") {
        setSelectedAccIds(valueArray);
      }
      if (filter.field == "status" && !notFilter.includes(2)) {
        setSelectedStatus(valueArray);
      }
      if (filter.field == "userId" && !notFilter.includes(3)) {
        setSelectedAssignees(
          usersListOption
            .filter((user) => valueArray.includes(user.key))
            .map((user) => user.value)
        );
      }
      if (filter.field == "alertId" && !notFilter.includes(4)) {
        setAlertId(filter.value);
      }
      setAnchorEl(null);
    });
  };

  //---------------------------------------------------------------------------------Functions
  //Components---------------------------------------------------------------------------------
  const FieldSelector = ({ options, value, onChangeHandler, name }) => {
    return (
      <>
        <Autocomplete
          {...filterAutoParam}
          options={options}
          value={value}
          onChange={(event, newValue) => onChangeHandler(newValue)}
          sx={{
            width: "10vw",
            "& .MuiInputBase-root": { height: "3vh", fontSize: "0.6vw" },
          }}
          renderOption={(props, option, { selected }, idx) => (
            <>
              <li
                key={idx}
                {...props}
                style={{ fontSize: "0.6vw", height: "3vh" }}
              >
                <Checkbox {...checkedParam} checked={selected} />
                {option}
              </li>
              <Divider
                flexItem
                sx={{ display: { xs: "none", md: "block" }, marginX: 1 }}
              />
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              {...filterTextParam}
              label={name}
              sx={{
                width: "10vw",
                "& .MuiInputBase-input": { fontSize: "0.6vw" },
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <></>)
          }
        />
      </>
    );
  };
  //---------------------------------------------------------------------------------Components
  return (
    <>
    {filterAction == 0 && <div
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  class="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3
                  class="text-base font-semibold leading-6 text-gray-900"
                  id="modal-title"
                >
                  Save Filter
                </h3>
                <div class="mt-2 z-100">
                  <TextField
                    placeholder="Filter Name"
                    variant="outlined"
                    value={filterName}
                    onChange={(event) => setFilterName(event.target.value)}
                    sx={{
                      width: "18vw",
                      "& .MuiInputBase-root": { height: "3vh", fontSize: "0.6vw" },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              onClick={() => onConfirmFilterSaveHandler("local")}
              class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => onConfirmFilterSaveHandler("global")}
              class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            >
              Save Globally
            </button>
            <button
              type="button"
              onClick={() => setFilterAction()}
              class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>}
      <div className="bg-white p-[10px] rounded-xl border-[1px] mb-[5px] shadow-inner border-purple-200 w-fit m-auto">
        <div
          className="flex flex-row flex-wrap justify-between items-center"
          style={{ gap: "10px" }}
        >
          <div id="filter-icon" className="flex-shrink-0 my-auto">
            <TuneIcon />
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", md: "block" }, marginX: 1 }}
          />
          <div className="flex-shrink-0 shadow-inner border-purple-300 rounded-[5px] border-[2px] p-1 w-fit">
            <Tooltip title="Load filter">
              <Button
                {...filterBtnParam}
                onClick={(event) => onPopOverHandler(event, 1)}
              >
                <DriveFolderUploadIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Save Filter">
              <Button {...filterBtnParam} onClick={() => setFilterAction(0)}>
                <SaveAsIcon />
              </Button>
            </Tooltip>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", md: "block" }, marginX: 1 }}
          />
          <div className="grid grid-cols-4 gap-4">
            <FieldSelector
              options={alertTypeOption}
              value={selectedTypes}
              onChangeHandler={setSelectedTypes}
              name={"Alert Type"}
            />
            <FieldSelector
              options={accountOption}
              value={selectedAccIds}
              onChangeHandler={setSelectedAccIds}
              name={"Account"}
            />
            {!notFilter.includes(2) && (
              <FieldSelector
                options={statusOptions}
                value={selectedStatus}
                onChangeHandler={setSelectedStatus}
                name={"Status"}
              />
            )}
            {!notFilter.includes(3) && (
              <FieldSelector
                options={usersListOption.map((user) => user.value)}
                value={selectedAssignees}
                onChangeHandler={setSelectedAssignees}
                name={"User"}
              />
            )}
            {!notFilter.includes(4) && (
              <FieldSelector
                options={["FRAUD", "NOT FRAUD"]}
                value={verdict}
                onChangeHandler={setVerdict}
                name={"Verdict"}
              />
            )}
            {filterFields.includes("Country") && (
              <FieldSelector
                options={countryOption}
                value={selectedCountry}
                onChangeHandler={setSelectedCountry}
                name={"Country"}
              />
            )}
            {filterFields.includes("Alert Id") && (
              <TextField
                size="small"
                variant="outlined"
                label="Alert Id"
                value={alertId}
                onChange={(e) => setAlertId(e.target.value)}
                InputLabelProps={{
                  style: { fontSize: "0.6vw" },
                }}
                sx={{
                  width: "10vw",
                  "& .MuiInputBase-root": { height: "3vh", fontSize: "0.6vw" },
                }}
              />
            )}
          </div>
          <Tooltip title="More Fields">
            <Button
              {...filterBtnParam}
              onClick={(event) => onPopOverHandler(event, 2)}
            >
              <MoreVertIcon />
            </Button>
          </Tooltip>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", md: "block" }, marginX: 1 }}
          />
          <div className="flex-shrink-0 shadow-inner border-violet-300 rounded-[5px] border-[2px] p-1 w-fit">
            <Tooltip title="Apply Filter">
              <Button {...filterBtnParam} onClick={onSearchHandler}>
                <SearchIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Reset Filter">
              <Button {...filterBtnParam} onClick={onClearSearchHandler}>
                <RefreshIcon />
              </Button>
            </Tooltip>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleSaveFilterPopClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {filterAction == 1 ? (
                savedFilter != null ? (
                  <>
                    <div className="mx-2 text-[0.6vw] text-blue-700 mt-2">
                      User Filters:
                    </div>
                    {Object.keys(savedFilter.userfilters).map((data, idx) => (
                      <MenuItem
                        id={idx}
                        value={data}
                        onClick={() => handleUserFilterSelected(data, "local")}
                        sx={{ fontSize: "0.6vw" }}
                      >
                        {data}
                      </MenuItem>
                    ))}
                    <Divider
                      flexItem
                      sx={{ display: { xs: "none", md: "block" }, marginX: 1 }}
                    />
                    <div className="mx-1 text-[0.6vw] text-blue-700">
                      Global Filters:
                    </div>
                    {Object.keys(savedFilter.globalfilters).map((data, idx) => (
                      <MenuItem
                        id={idx}
                        value={data}
                        onClick={() => handleUserFilterSelected(data, "global")}
                        sx={{ fontSize: "0.6vw" }}
                      >
                        {data}
                      </MenuItem>
                    ))}
                  </>
                ) : (
                  <div className="p-1 text-xs text-red-300">
                    No Saved Filter
                  </div>
                )
              ) : (
                <>
                  <Autocomplete
                    {...filterAutoParam}
                    options={["Alert Id", "Country"]}
                    value={filterFields}
                    onChange={(event, newValue) => setFilterFields(newValue)}
                    renderOption={(props, option, { selected }, idx) => (
                      <>
                        <li
                          key={idx}
                          {...props}
                          style={{ fontSize: "0.6vw", height: "3vh" }}
                        >
                          <Checkbox {...checkedParam} checked={selected} />
                          {option}
                        </li>
                        <Divider
                          flexItem
                          sx={{
                            display: { xs: "none", md: "block" },
                            marginX: 1,
                          }}
                        />
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...filterTextParam}
                        label="Filter Fields"
                      />
                    )}
                  />
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterComponent;
