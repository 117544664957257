import { Divider } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNotification } from "../../../utils/Notification/NotificationContext.jsx";
import { RoleRequest } from "../../../../dto/RequestDTO.ts";
import { OverlayContext } from "../../../utils/OverLay.jsx";
import {
  ConfirmationWindow,
  isObjectInArray,
} from "../../../../service/Util.js";

export default function UpdateUserRoleForm({ availableRoles, userRoleAssigHandler }) {
  console.log(availableRoles);
  /*States---------------------------------------------------------------------------------*/
  const [filterQuery, setFilterQuery] = useState("");
  const [filteredUser, setFilteredUser] = useState();
  const [addUser, setAddUser] = useState();
  const [removeUser, setRemoveUser] = useState();
  const [selectedRole, setSelectedRole] = useState();
  /*---------------------------------------------------------------------------------States*/
  /*Context---------------------------------------------------------------------------------*/
  const { showNotification } = useNotification();
  const overlayContext = useContext(OverlayContext);
  /*---------------------------------------------------------------------------------Context*/
  /*Function-------------------------------------------------------------------------------*/
    const onConfirmAddRoleHandler=(action)=>{
      if(action==="confirm"){
        userRoleAssigHandler(selectedRole,"add")
      }
        overlayContext.setShowOverlay(false);
        setSelectedRole()
    }

  /*-------------------------------------------------------------------------------Function*/

  /*SideEffects----------------------------------------------------------------------------*/
  // useEffect(() => {
  //   setFilteredUser((prev) => [
  //     ...availableUsers.filter((user) =>
  //       user.name.toLowerCase().includes(filterQuery.toLowerCase())
  //     ),
  //   ]);
  // }, [filterQuery,availableUsers]);

  useEffect(() => {
    console.log(selectedRole) 
    if (selectedRole) {
      overlayContext.setOverlayContent(() =>
        <ConfirmationWindow confirmationHandler={onConfirmAddRoleHandler} msg={`Add Role: ${selectedRole.name} to user`}/>
      );
      overlayContext.setShowOverlay(true);
    }
  }, [selectedRole]);

  /*----------------------------------------------------------------------------SideEffects*/
  return (
    <div className="flex flex-row">
      <div className="p-2 overflow-y-auto h-[55vh] flex flex-col  mt-3 rounded-[5px]">
        {availableRoles.map((role) => (
          <div className="mb-10 border-blue-100 w-fit shadow-lg p-2 rounded-lg border-2" key={role.id}>
            <div className="border-[0.1vw] px-5 rounded-[8px] shadow-lg bg-white flex flex-col text-[0.7vw] w-[20vw] border-blue-300">
              <p>
                <span className="text-[0.6vw]">{role.name}</span>
              </p>
              <p>
                <span className="text-[0.5vw] text-gray-400">
                  {role.description}
                </span>
              </p>
            </div>
            <div className="relative ">
              {(role &&
                role.permissions.length > 0 &&
                role.permissions.map((perm,idx) => (
                  <div className="flex flex-row relative" key={idx}>
                    <div className="h-[3.2vh] border-blue-300 border-[0.01vw] mt-auto mb-auto"></div>
                    <div className="h-[1px] w-[2vh] border-blue-300 border-[0.01vw] mt-auto "></div>
                    <div className="border-[2px] mt-auto rounded-sm flex flex-col text-[0.7vw]  rounded-t-[10px] px-5 bg-slate-100 border-blue-300 text-blue-600">
                      <p>
                        <span className="text-[0.5vw]">{perm.name}</span>
                        <span className="text-[0.5vw]">{": "}</span>
                        <span className="text-[0.4vw] text-red-400">
                          {perm.description}
                        </span>
                      </p>
                    </div>
                  </div>
                ))) || (
                <div className="flex flex-row">
                  <div className="h-[4vh] w-[1px] border-blue-300 border-[0.01vw] mt-auto mb-auto"></div>
                  <div className="h-[1px] w-[2vh] border-blue-300 border-[0.01vw]  mt-auto"></div>
                  <div className="border-[2px] mt-auto rounded-sm flex flex-col text-[0.7vw]  rounded-t-[10px] px-5 bg-red-50 border-blue-300">
                    <p>
                      <span className="text-[0.5vw] text-red-500">
                        {"No Permission attached to this role"}
                      </span>
                    </p>
                  </div>
                </div>
              )}
              <button onClick={()=>setSelectedRole(role)} className="border-2 px-5 rounded-[5px] mt-2 bg-green-600 text-white font-semibold border-green-600 shadow-sm hover:shadow-2xl active:scale-[0.9] duration-200">
                Add
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
