export class ResponseRoles {
  roleList: Array<Role> = [];
  applyData(json: Object) {
    Object.assign(this, json);
  }
}

class Role {
  id: string;
  name: string;
  description: string;
  status: string;
  totalUsers: string;
  users: Array<User> = [];
  permissions: Array<Permission> = [];
}

class User {
  userId: string;
  name: string;
  email: string;
  status: string;
  lastLogin: string;
}

class Permission {
  id: string;
  name: string;
  description: string;
}
