import { Divider } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import useHttp from "../../../hooks/useHttp.js";
import { useOktaAuth } from "@okta/okta-react";
import { PageLoaderContext } from "../../utils/PageLoader.jsx";
import {
  UserDetailResponse,
} from "../../../dto/ResponeDTO.ts";
import {
  assignRole,
  createNewRole,
  getAllPermission,
  getAllRoles,
  getUsersList,
  modifyRolePerm,
  unassignRole,
} from "../../../service/Endpoints.js";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import CreateRoleForm from "./components/CreateRoleForm.jsx";
import { useNotification } from "../../utils/Notification/NotificationContext.jsx";
import UpdateRolePermForm from "./components/UpdateRolePermForm.jsx";
import { isObjectInArray } from "../../../service/Util.js";
import { RolePermissionRequest, UserRoleRequest } from "../../../dto/RequestDTO.ts";
import UpdateRoleUserForm from "./components/UpdateRoleUserForm.jsx";
import { ResponseRoles } from "../../../dto/ResponsRoles.ts";
import { ResponsePermissions } from "../../../dto/ResponsPermissions.ts";
import { AppDataContext } from "../../../App.jsx";

export default function Role() {
  /*States---------------------------------------------------------------------------------*/
  const [selectedRole, setSelectedRole] = useState();
  const [query, setQuery] = useState("");
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [roleUsers, setRoleUsers] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [allRoles, setAllRoles] = useState(new ResponseRoles());
  const [allPermission, setAllPermissions] = useState(
    new ResponsePermissions()
  );
  const [userAction, setUserAction] = useState();
  const [appUsers, setAppUsers] = useState( new ResponseRoles());
  /*---------------------------------------------------------------------------------States*/

  /*Hooks----------------------------------------------------------------------------------*/
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();
  /*----------------------------------------------------------------------------------Hooks*/

  /*Function--------------------------------------------------------------------------------*/
  const onCreateRole = (request) => {
    if(request)
      createRoleApi(request);
    else
    setUserAction()
  };

  const onModifyRolePermission = (addPermList=[], removePermList=[]) => {
    if(addPermList.length>0 || removePermList.length>0){
      const rolePermRequest = new RolePermissionRequest();
      rolePermRequest.roleId = selectedRole.id;
      rolePermRequest.addPermissionList = addPermList.map((perm) => perm.name);
      rolePermRequest.removePermissionList=removePermList.map((perm) => perm.name);
      modifyRolePermApi(rolePermRequest)
    }
    setUserAction()
  };

  const userRoleAssigHandler = (userId,action) => {
    if(!action)
      setUserAction()
    const request= new UserRoleRequest();
    request.roleIds=[selectedRole.id]
    request.userId=userId
    if(action=="add")
      assignRoleApi(request)
    if(action=="remove")
      unassignRoleApi(request)
    
  }

  /*--------------------------------------------------------------------------------Function*/

  /*Constants-------------------------------------------------------------------------------*/

  const ACTION=["CREATE","MODIFY_PERM", "MODIFY_USER"]
  /*-------------------------------------------------------------------------------Constants*/

  /*Context---------------------------------------------------------------------------------*/
  const pageLoaderContext = useContext(PageLoaderContext);
  const { showNotification } = useNotification();
  const selectTabContext = useContext(AppDataContext);
  selectTabContext.setSelectedTab(4);
  /*---------------------------------------------------------------------------------Context*/

  /*SideEffects-----------------------------------------------------------------------------*/
  useEffect(() => {
    pageLoaderContext.showLoader(isLoading);
  }, [isLoading]);
  useEffect(() => {
    error && showNotification(error, "error");
  }, [error]);

  useEffect(() => {
    let pageable = new PagableFilter();
    getRolesFromAPI(pageable);
    getPermFromAPI();
    getAllUserApi()
  }, []);

  useEffect(() => {
    setFilteredRoles((prev) => [
      ...allRoles.roleList.filter((role) =>
        role.name.toLowerCase().includes(query.toLowerCase())
      ),
    ]);
  }, [query]);

  useEffect(() => {
    if (selectedRole && selectedRole.users) setRoleUsers(selectedRole.users);
    else setRoleUsers([]);

    if (selectedRole && selectedRole.permissions)
      setRolePermissions(selectedRole.permissions);
    else setRolePermissions([]);
  }, [selectedRole]);
  /*-----------------------------------------------------------------------------SideEffects*/
  /*APICalls--------------------------------------------------------------------------------*/
  const getRolesFromAPI = useCallback((pagableFilter) => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAllRoles(pagableFilter) },
      (response) => {
        const responseBody = new ResponseRoles();
        responseBody.applyData(response);
        setAllRoles(responseBody);
        setFilteredRoles(responseBody.roleList);
        if(selectedRole){
          const selectedRoleUpdated=responseBody.roleList.filter(role=>role.id===selectedRole.id)[0]
          setSelectedRole(selectedRoleUpdated)
        }
        else{
          setSelectedRole(responseBody.roleList[0])
        }
      },
      token
    );
  });

  const getPermFromAPI = useCallback((pagableFilter) => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAllPermission() },
      (response) => {
        const responseBody = new ResponsePermissions();
        responseBody.applyData(response);
        setAllPermissions(responseBody);
      },
      token
    );
  });

  const createRoleApi = useCallback((request) => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: createNewRole(),
        method: "POST",
        body: request,
        headers: {
          "Content-Type": "application/json",
        },
      },
      (response) => {
        showNotification("Role Created");
        let pageable = new PagableFilter();
        getRolesFromAPI(pageable);
      },
      token
    );
  });

  const modifyRolePermApi = useCallback((request) => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: modifyRolePerm(),
        method: "PUT",
        body: request,
        headers: {
          "Content-Type": "application/json",
        },
      },
      (response) => {
        showNotification("Role Modified");
        let pageable = new PagableFilter();
        getRolesFromAPI(pageable);
      },
      token
    );
  });

  const getAllUserApi = useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      {url: getUsersList()},
      (response) => {
        const responseBody = new UserDetailResponse();
        responseBody.applyData(response);
        setAppUsers(responseBody)
      },
      token
    );
  });
  const assignRoleApi = useCallback((request) => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: assignRole(),
        method: "PUT",
        body: request,
        headers: {
          "Content-Type": "application/json",
        },
      },
      (response) => {
        showNotification("Role assigned to user");
        let pageable = new PagableFilter();
        getRolesFromAPI(pageable);
      },
      token
    );
  });

  const unassignRoleApi = useCallback((request) => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: unassignRole(),
        method: "DELETE",
        body: request,
        headers: {
          "Content-Type": "application/json",
        },
      },
      (response) => {
        showNotification("Role unassigned from user");
        let pageable = new PagableFilter();
        getRolesFromAPI(pageable);
      },
      token
    );
  });
  /*---------------------------------------------------------------------------------APICalls*/
  return (
    <>
      <div className="rounded-xl flex flex-row mt-[5vh]  m-auto justify-start">
        <div className="h-[70vh] w-[20vw] bg-white p-2 shadow-xl border-[1px] border-purple-500 rounded-xl m-auto">
          <div className="text-gray-500 font-semibold text-[0.9vw] w-fit m-auto">
            <p>Roles</p>
          </div>
          <Divider sx={{ my: 1 }} />
          <div className="flex items-center border rounded-lg shadow-md overflow-hidden">
            <div className="px-3 text-gray-500">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="px-4 py-2 w-80 outline-none text-[0.7vw] h-[2vh]"
              placeholder="Search roles"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <button
            className="rounded-lg active:scale-[0.9] duration-200 mt-2 text-blue-500 underline text-[0.6vw]"
            onClick={() => setUserAction(ACTION[0])}
          >
            Create New Role
          </button>
          <div className="p-2 overflow-y-auto h-[55vh] flex flex-col gap-4 mt-3 border-[1px] shadow-inner rounded-[5px]">
            {filteredRoles.map((role) => (
              <div
                onClick={() => setSelectedRole(role)}
                className={`bg-white w-full  p-1 px-5 rounded-[20px]  hover:scale-[1.02] duration-200 cursor-pointer active:scale-[0.95] text-[0.7vw] alert-card-entry-animation ${
                  selectedRole && selectedRole.id == role.id
                    ? "bg-purple-200 border-[1px] border-violet-300"
                    : "bg-purple-100"
                }`}
              >
                {role.name}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row border-2 rounded-lg p-1 shadow-lg h-[50vh] w-[70vw]">
          <div className="h-[50vh] w-[25vw] p-2 border-r-[1px] h-[30vh]">
            <div className="text-gray-500 font-semibold text-[0.8vw] w-fit m-auto">
              <p>Details</p>
            </div>
            <Divider sx={{ my: 1 }} />
            <div className="flex flex-row gap-6">
              <div className="flex flex-col text-gray-400 font-medium text-[0.7vw] gap-5">
                <p>
                  Name:{" "}
                  <span className="text-gray-600">
                    {selectedRole && selectedRole.name}
                  </span>
                </p>
                <div className="w-[85%] flex flex-row">
                  Description:{" "}
                  <span className="text-gray-600 text-[0.6vw]">
                    {selectedRole && selectedRole.description}
                  </span>
                </div>
                <p>
                  Status:
                  <span className="text-gray-600">
                    {selectedRole && selectedRole.status}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="h-[50vh] w-[20vw] p-2 border-r-[1px]">
            <div className="text-gray-500 font-semibold text-[0.8vw] w-fit m-auto">
              <p>Permissions</p>
            </div>
            <Divider sx={{ my: 1 }} />
            <button
              className="rounded-lg active:scale-[0.9] duration-200 mt-2 text-blue-500 underline text-[0.6vw]"
              onClick={() => selectedRole && setUserAction(ACTION[1])}
            >
              Update Permissions
            </button>
            <div className="overflow-y-auto h-[40vh] shadow-inner p-2 rounded-lg">
            {rolePermissions.map((perm) => (
              <div className="border-2 p-2 rounded-lg bg-white flex flex-col gap-2 text-[0.7vw] alert-card-entry-animation">
                <p>
                  <span className="text-medium">{perm.name}</span>
                </p>
                <p>
                  <span className="text-[0.6vw] text-gray-400">
                    {perm.description}
                  </span>
                </p>
              </div>
            ))}
            </div>
          </div>
          <div className="h-[50vh] w-[20vw] p-2">
            <div className="text-gray-500 font-semibold text-[0.8vw] w-fit m-auto">
              <p>Users</p>
            </div>
            <Divider sx={{ my: 1 }} />
            <button
              className="rounded-lg active:scale-[0.9] duration-200 mt-2 text-blue-500 underline text-[0.6vw] "
              onClick={() => setUserAction(ACTION[2])}
            >
              Update Users
            </button>
            <div className="overflow-y-auto h-[40vh] shadow-inner p-2 rounded-lg">
            {roleUsers.map((user) => (
              <div className="border-2 p-2 rounded-lg bg-white flex flex-col  text-[0.8vw] alert-card-entry-animation">
                <p>
                  <span>{user.name}</span>
                </p>
                <p>
                  <span className="text-[0.6vw] text-blue-500">
                    {user.email}
                  </span>
                </p>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
      {userAction===ACTION[0] && (
        <div className="rounded-xl flex flex-row  gap-2 bg-white/70 w-full absolute top-0 bottom-0 left-0 right-0 w-full backdrop-blur-sm h-full">
          <div className="flex flex-col bg-white m-auto border-2 rounded-lg p-2 border-blue-400">
            Create New Role
            <CreateRoleForm
              allPermission={allPermission}
              onCreateRole={onCreateRole}
            />
          </div>
        </div>
      )}
      {userAction===ACTION[1] && (
        <div className="rounded-xl flex flex-row  gap-2 bg-white/70 w-full absolute top-0 bottom-0 left-0 right-0 w-full backdrop-blur-sm h-full">
          <div className="flex flex-col bg-white m-auto border-2 rounded-lg p-2 border-blue-400">
            Update Role Permissions
            <UpdateRolePermForm
              availablePermission={allPermission.permissionList.filter(perm=>!isObjectInArray(rolePermissions,perm))}
              onModifyRolePermission={onModifyRolePermission}
              existingPermission={rolePermissions}
            />
          </div>
        </div>
      )}
      {userAction===ACTION[2] && (
        <div className="rounded-xl flex flex-row  gap-2 bg-white/70 w-full absolute top-0 bottom-0 left-0 right-0 w-full backdrop-blur-sm h-full">
          <div className="flex flex-col bg-white m-auto border-2 rounded-lg p-2 border-blue-400 ">
            Update Role Users
            <UpdateRoleUserForm
              availableUsers={appUsers.users.filter(appUser=>!isObjectInArray(roleUsers,appUser))}
              userRoleAssigHandler={userRoleAssigHandler}
              existingUsers={roleUsers}
            />
          </div>
        </div>
      )}
    </>
  );
}
