export const CounterDisplay = ({ status, createdOn, assignedStatusOn, id }) => {
  const now = new Date();
  const targetDate = status == "NEW" ? createdOn : assignedStatusOn + "Z";
  const diffTime = now.getTime() - new Date(targetDate).getTime();
  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  let hr = Math.floor(diffMinutes / 60);
  const min = diffMinutes % 60;
  let day = 0;
  if (hr > 24) {
    day = Math.floor(hr / 24);
    hr = hr % 24;
  }
  let msg = status == "NEW" ? "Unassigned for" : "Pending for";
  let colour = "bg-green-500";
  if (min > 20 || hr>0) {
    colour = "bg-orange-500";
  }
  if (day >= 1) {
    colour = "bg-red-500";
  }

  return (
    <div>
        <p className="text-[0.5vw] font-semibold">{msg}</p>
      <div
        className={`${colour} text-white h-[2vh] min-w-[4vw] w-fit rounded-[5px] shadow-lg relative flex justify-center items-center`}
      >
        {day>0 &&
        <p className="text-[0.7vw] mt-[0.5vh] font-semibold">
          {day}
          <span className="text-[0.5vw] font-semibold mr-1">d</span>
        </p>}
        <p className="text-[0.7vw] mt-[0.5vh] font-semibold">
          {hr}
          <span className="text-[0.5vw] font-semibold">h</span>
        </p>
        {day<=0 &&<>
        <p className="text-[0.7vw] mt-[0.5vh] font-semibold ml-1">
          {min}
          <span className="text-[0.5vw] font-semibold">m</span>
        </p>
        </>}
      </div>{" "}
      
    </div>
  );
};
