export class ResponseUserRoles {
  userList: Array<User> = [];
  applyData(json: Object) {
    Object.assign(this, json);
  }
}

class User {
  userId: string;
  name: string;
  email: string;
  status: string;
  lastLogin: string;
  locale:string;
  region:string;
  roles: Array<Role> = [];
}

class Role {
  id: string;
  name: string;
  description: string;
  permissions: Array<Permission> = [];
}

class Permission {
  name: string;
  description: string;
}
