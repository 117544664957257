const endPoint = window._env_.REACT_APP_BACKEND_HOST;

const createFilterString = (filters) => {
  let filterString = "";

  for (let filter of filters) {
    if (filterString) filterString = filterString + " AND ";
    let operator = "=";
    switch (filter.operator) {
      case "equals":
        operator = "=";
        break;
      case "contains":
        operator = "like";
        break;
      case "before":
        operator = "<";
        break;
      case "after":
        operator = ">";
        break;
      case "in":
        operator = "in";
        break;
      case "not":
        operator = "!=";
        break;
      default:
        operator = "=";
    }
    filterString = `${filterString}${filter.field} ${operator} ${filter.value}`;
  }
  return filterString;
};


/*User--------------------------------------------------------------------------------------------------------------------------------*/
export function setUserFilter() {
  return `${endPoint}/user/filter`;
}
export function setGlobalFilter() {
  return `${endPoint}/user/filter/global`;
}
export function getUserFilter() {
  return `${endPoint}/user/filter`;
}
export function getUsersList() {
  return `${endPoint}/user/userList`;
}

export function userLogin() {
  return `${endPoint}/user/login`;
}

export function getAllRoles(pagableFilter) {
  let filterString = createFilterString(pagableFilter.filters);
  return `${endPoint}/user/roles?filter=${filterString}`;
}

export function getAllPermission() {
  return `${endPoint}/user/perm`;
}

export function createNewRole() {
  return `${endPoint}/user/createRole`;
}

export function modifyRolePerm() {
  return `${endPoint}/user/modifyPerm`;
}
export function assignRole() {
  return `${endPoint}/user/assignRole`;
}
export function unassignRole() {
  return `${endPoint}/user/unassignRole`;
}
export function userRoles() {
  return `${endPoint}/user/userRoles`;
}
export function enableUser() {
  return `${endPoint}/user/activate`;
}
export function disableUser() {
  return `${endPoint}/user/deactivate`;
}
/*--------------------------------------------------------------------------------------------------------------------------------User*/

/*URL Scanner--------------------------------------------------------------------------------------------------------------------------------*/
export function getUrlScannedData(pagableFilter) {
  let filterString = createFilterString(pagableFilter.filters);
  return `${endPoint}/scan/url-scanned?filter=${filterString}&limit=${pagableFilter.limit}&offset=${pagableFilter.offset}`;
}

export function getUrlLiveScan() {
  return `${endPoint}/scan/liveScan`;
}

export function exportUrlScanData() {
  return `${endPoint}/scan/export`;
}
/*--------------------------------------------------------------------------------------------------------------------------------URL Scanner*/

/*Alerts--------------------------------------------------------------------------------------------------------------------------------*/
export function getAlertTypes() {
  return `${endPoint}/alert/getTypes`;
}

export function getAlertCountries() {
  return `${endPoint}/alert/getCountries`;
}
export function getAlertAccId() {
  return `${endPoint}/alert/getAccIds`;
}
export function setAlertVerdict(alertId, verdict) {
  return `${endPoint}/alert/setVerdict?alertId=${alertId}&verdict=${verdict}`;
}
export function checkOverride() {
  return `${endPoint}/alert/checkOverride`;
}
export function overrideVerdict(alertId, verdict) {
  return `${endPoint}/alert/overrideVerdict?alertId=${alertId}&verdict=${verdict}`;
}
export function getAllAlerts(pagableFilter) {
  let filterString = createFilterString(pagableFilter.filters);
  return `${endPoint}/alert/allAlert?filter=${filterString}&limit=${pagableFilter.limit}&offset=${pagableFilter.offset}`;
}

export function getArchiveAlerts(pagableFilter) {
  let filterString = createFilterString(pagableFilter.filters);
  return `${endPoint}/alert/allArchive?filter=${filterString}&limit=${pagableFilter.limit}&offset=${pagableFilter.offset}`;
}

export function getAlertData(alertId) {
  return `${endPoint}/alert/data?alertId=${alertId}`;
}

export function getArchiveAlertData(alertId) {
  return `${endPoint}/alert/archive/data?alertId=${alertId}`;
}

export function assignAlert(alertId) {
  return `${endPoint}/alert/assignAlert?alertId=${alertId}`;
}

export function getMonthStats(monthId) {
  return `${endPoint}/alert/stats/monthly?month=${monthId}`;
}

export function getYearlyStats(year) {
  return `${endPoint}/alert/stats/yearly?year=${year}`;
}

/*--------------------------------------------------------------------------------------------------------------------------------Alerts*/
