import { Divider } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNotification } from "../../../utils/Notification/NotificationContext.jsx";
import { RoleRequest } from "../../../../dto/RequestDTO.ts";
import { OverlayContext } from "../../../utils/OverLay.jsx";
import { ConfirmationWindow, isObjectInArray } from "../../../../service/Util.js";


export default function UpdateRoleUserForm({ availableUsers, userRoleAssigHandler, existingUsers }) {
  /*States---------------------------------------------------------------------------------*/
  const [filterQuery, setFilterQuery] = useState("");
  const [filteredUser, setFilteredUser] = useState(availableUsers);
  const [addUser, setAddUser] = useState();
  const [removeUser, setRemoveUser] = useState();
  /*---------------------------------------------------------------------------------States*/
  /*Context---------------------------------------------------------------------------------*/
  const { showNotification } = useNotification();
  const overlayContext = useContext(OverlayContext);
  /*---------------------------------------------------------------------------------Context*/
  /*Function-------------------------------------------------------------------------------*/
  const onConfirmation = () =>{
    onModifyRolePermission(addPermList,removePermList)
  };
  const onCancel = () => {
    userRoleAssigHandler();
  };

  const onConfirmAddUserHandler=(action)=>{
    if(action==="confirm"){
      userRoleAssigHandler(addUser.userId,"add")
    }
      overlayContext.setShowOverlay(false);
      setAddUser()
  }
  const onConfirmRemoveUserHandler=(action)=>{
    if(action==="confirm"){
      userRoleAssigHandler(removeUser.userId,"remove")
    }
    overlayContext.setShowOverlay(false);
    setRemoveUser()
}
  
  /*-------------------------------------------------------------------------------Function*/

  /*SideEffects----------------------------------------------------------------------------*/
  useEffect(() => {
    setFilteredUser((prev) => [
      ...availableUsers.filter((user) =>
        user.name.toLowerCase().includes(filterQuery.toLowerCase())
      ),
    ]);
  }, [filterQuery,availableUsers]);
  
  useEffect(() => {
    if (addUser) {
      overlayContext.setOverlayContent(() =>
        <ConfirmationWindow confirmationHandler={onConfirmAddUserHandler} msg={`Add User: ${addUser.name} to role`}/>
      );
      overlayContext.setShowOverlay(true);
    }
    if (removeUser) {
      overlayContext.setOverlayContent(() =>
      <ConfirmationWindow confirmationHandler={onConfirmRemoveUserHandler} msg={`Remove User: ${removeUser.name} from role`}/>
      );
      overlayContext.setShowOverlay(true);
    }
  }, [addUser,removeUser]);

  /*----------------------------------------------------------------------------SideEffects*/
  return (
    <div className="flex flex-row">
      <div className="flex flex-row p-2">
        <div className="h-[30vh] w-[20vw] p-5  border-[1px] rounded-l-xl ml-2">
          <div className="text-blue-500 font-semibold text-[0.6vw] w-fit m-auto">
            <p>Available Users</p>
          </div>
          <Divider sx={{ my: 1 }} />
          <div className="flex items-center border rounded-[4px] shadow-sm overflow-hidden">
            <div className="px-3 text-gray-500">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="px-4 py-2 w-80 outline-none text-[0.7vw] h-[2vh]"
              placeholder="Search permissions"
              value={filterQuery}
              onChange={(e) => setFilterQuery(e.target.value)}
            />
          </div>
          <div className="p-2 overflow-y-auto h-[18vh] flex flex-col gap-4 ">
            {filteredUser.map((user) => (
              <div
                onClick={() => setAddUser(user)}
                className={`bg-white w-full  px-5 py-2 rounded-[20px] shadow-md  hover:scale-[1.02] duration-200 cursor-pointer active:scale-[0.95] text-[0.7vw] bg-gray-200/40 flex flex-row justify-between`}
              >
                <div className="flex flex-col">
                <p className="font-medium text-[0.5vw]">{user.name}</p>
                  <p className="text-[0.5vw] text-red-400">
                    {user.email}
                  </p>
                </div>
                <p className="text-blue-500 text-[0.6vw]">Add</p>
              </div>
            ))}
          </div>
        </div>
        <div className="h-[30vh] w-[20vw] p-5 rounded-r-xl border-[1px]">
          <div className="text-green-500 font-semibold text-[0.6vw] w-fit m-auto">
            <p>
              Existing User
            </p>
          </div>
          <Divider sx={{ my: 1 }} />
          <div className="p-2 overflow-y-auto h-[18vh] flex flex-col gap-4">
            {existingUsers.map((user) => (
              <div
                onClick={() => setRemoveUser(user)}
                className={`bg-white w-full px-5 py-2  p-1 rounded-[20px] shadow-md  hover:scale-[1.02] duration-200 cursor-pointer active:scale-[0.95] text-[0.7vw] bg-green-50 flex flex-row justify-between`}
              >
                <div className="flex flex-col">
                  <p className="font-medium text-[0.5vw]">{user.name}</p>
                  <p className="text-[0.5vw] text-red-400">
                    {user.email}
                  </p>
                </div>
                <p className="text-red-500 text-[0.6vw]">Remove</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-5 m-auto ml-10">
          <button className="w-[5vw] border-[1px] rounded-xl border-red-400 text-red-500 hover:scale-[1.1] duration-200 active:scale-[0.9]"
          onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
