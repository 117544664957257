import { Divider } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import useHttp from "../../../hooks/useHttp.js";
import { useOktaAuth } from "@okta/okta-react";
import { PageLoaderContext } from "../../utils/PageLoader.jsx";
import {
  assignRole,
  disableUser,
  enableUser,
  getAllRoles,
  unassignRole,
  userRoles,
} from "../../../service/Endpoints.js";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import { useNotification } from "../../utils/Notification/NotificationContext.jsx";
import { ResponseUserRoles } from "../../../dto/ResponseUserRoles.ts";
import { ResponseRoles } from "../../../dto/ResponsRoles.ts";
import { AppDataContext } from "../../../App.jsx";
import UpdateUserRoleForm from "./components/UpdateUserRoleForm.jsx";
import { UserRoleRequest } from "../../../dto/RequestDTO.ts";
import { OverlayContext } from "../../utils/OverLay.jsx";
import { ConfirmationWindow } from "../../../service/Util.js";
import GppBadIcon from "@mui/icons-material/GppBad";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

export default function User() {
  /*States---------------------------------------------------------------------------------*/
  const [query, setQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [allRoles, setAllRoles] = useState(new ResponseRoles());
  const [userAction, setUserAction] = useState(false);
  const [users, setUsers] = useState(new ResponseUserRoles());
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  /*---------------------------------------------------------------------------------States*/

  /*Hooks----------------------------------------------------------------------------------*/
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();
  /*----------------------------------------------------------------------------------Hooks*/

  /*Context---------------------------------------------------------------------------------*/
  const pageLoaderContext = useContext(PageLoaderContext);
  const { showNotification } = useNotification();
  const selectTabContext = useContext(AppDataContext);
  selectTabContext.setSelectedTab(4);
  const overlayContext = useContext(OverlayContext);
  /*---------------------------------------------------------------------------------Context*/

  /*SideEffects-----------------------------------------------------------------------------*/
  useEffect(() => {
    pageLoaderContext.showLoader(isLoading);
  }, [isLoading]);
  useEffect(() => {
    error && showNotification(error, "error");
  }, [error]);

  useEffect(() => {
    getUserRoleApi();
    let pageable = new PagableFilter();
    getRolesFromAPI(pageable);
  }, []);

  useEffect(() => {
    setFilteredUsers((prev) => [
      ...users.userList.filter((user) =>
        user.name.toLowerCase().includes(query.toLowerCase())
      ),
    ]);
  }, [query]);

  useEffect(() => {
    if (selectedUser) {
      setUserAction(false);
      setSelectedUserRoles(selectedUser.roles);
    }
  }, [selectedUser]);
  useEffect(() => {
    setFilteredUsers(users.userList);
  }, [users]);
  /*-----------------------------------------------------------------------------SideEffects*/

  /*Function--------------------------------------------------------------------------------*/
  const userRoleAssigHandler = (role, action) => {
    const request = new UserRoleRequest();
    request.roleIds = [role.id];
    request.userId = selectedUser.userId;
    if (action == "add") {
      assignRoleApi(request, (resp) => {
        showNotification("Role assigned to user");
      });
    }
    if (action == "remove") {
      unassignRoleApi(request, (resp) => {
        showNotification("Role unassigned from user");
      });
    }
    getUserRoleApi();
    setUserAction();
  };

  const onConfirmRemoveRoleHandler = (action, roleId) => {
    if (action === "confirm") {
      userRoleAssigHandler(roleId, "remove");
    }
    overlayContext.setShowOverlay(false);
  };
  const removeRoleHandler = (role) => {
    overlayContext.setOverlayContent(() => (
      <ConfirmationWindow
        confirmationHandler={(action) =>
          onConfirmRemoveRoleHandler(action, role)
        }
        msg={`Remove Role: ${role.name} from user`}
      />
    ));
    overlayContext.setShowOverlay(true);
  };

  const onUserStatusChange = (action) => {
    const request = new UserRoleRequest();
    request.userId = selectedUser.userId;
    if (action == 1) {
      activateUser(request, (respObj) => {
        showNotification("User Activated");
        setSelectedUser((prev) => ({ ...prev, status: "1" }));
        setUsers(prev=>{
          let temp=prev.userList.map(user=>user.userId===selectedUser.userId?{...user, status: "1"}:user)
          return {...prev,"userList":temp}
        })
      });
    } else if (action == 0) {
      deactivateUser(request, (respObj) => {
        showNotification("User Deactivated");
        setSelectedUser((prev) => ({ ...prev, status: "0" }));
        setUsers(prev=>{
          let temp=prev.userList.map(user=>user.userId===selectedUser.userId?{...user, status: "0"}:user)
          return {...prev,"userList":temp}
        })
      });
    }
  };
  /*--------------------------------------------------------------------------------Function*/
  /*APICalls--------------------------------------------------------------------------------*/
  const getRolesFromAPI = useCallback((pagableFilter) => {
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAllRoles(pagableFilter) },
      (response) => {
        const responseBody = new ResponseRoles();
        responseBody.applyData(response);
        setAllRoles(responseBody);
      },
      token
    );
  });

  const activateUser = useCallback((request, responseHandler) => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: enableUser(),
        method: "PUT",
        body: request,
        headers: {
          "Content-Type": "application/json",
        },
      },
      responseHandler,
      token
    );
  });

  const deactivateUser = useCallback((request, responseHandler) => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: disableUser(),
        method: "DELETE",
        body: request,
        headers: {
          "Content-Type": "application/json",
        },
      },
      responseHandler,
      token
    );
  });

  const assignRoleApi = useCallback((request, responseHandler) => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: assignRole(),
        method: "PUT",
        body: request,
        headers: {
          "Content-Type": "application/json",
        },
      },
      responseHandler,
      token
    );
  });

  const unassignRoleApi = useCallback((request, responseHandler) => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: unassignRole(),
        method: "DELETE",
        body: request,
        headers: {
          "Content-Type": "application/json",
        },
      },
      responseHandler,
      token
    );
  });

  const getUserRoleApi = useCallback(() => {
    let token = authState.accessToken.accessToken;
    callApi(
      {
        url: userRoles(),
      },
      (respObj) => {
        const response = new ResponseUserRoles();
        response.applyData(respObj);
        setUsers(response);
        setFilteredUsers(response.userList);
        setSelectedUser(response.userList[0]);
      },
      token
    );
  });
  /*---------------------------------------------------------------------------------APICalls*/
  return (
    <>
      <div className="rounded-xl flex flex-row mt-[5vh]  m-auto justify-start">
        <div className="h-[70vh] w-[20vw] bg-white p-2 shadow-xl border-[1px] border-purple-500 rounded-xl m-auto">
          <div className="text-gray-500 font-semibold text-[0.9vw] w-fit m-auto">
            <p>Users</p>
          </div>
          <Divider sx={{ my: 1 }} />
          <div className="flex items-center border rounded-lg shadow-md overflow-hidden">
            <div className="px-3 text-gray-500">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="px-4 py-2 w-80 outline-none text-[0.7vw] h-[2vh]"
              placeholder="Search roles"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="p-2 overflow-y-auto h-[55vh] flex flex-col gap-4 mt-3 border-[1px] shadow-inner rounded-[5px]">
            {filteredUsers.map((user) => (
              <div
                onClick={() => setSelectedUser(user)}
                className={`bg-white w-full  p-1 px-5 rounded-[20px]  hover:scale-[1.02] duration-200 cursor-pointer active:scale-[0.95] text-[0.7vw] alert-card-entry-animation ${
                  selectedUser && selectedUser.userId == user.userId
                    ? "bg-purple-200 border-[1px] border-violet-300"
                    : "bg-purple-100"
                }`}
              >
                {user.name}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row border-2 rounded-lg p-1 shadow-lg h-[65vh] w-[70vw]">
          <div className="h-[50vh] p-2 border-r-[1px] w-[40%]">
            <div>
              <AccountCircleIcon
                sx={{ height: "8vh", width: "15vw", marginLeft: "2vw" }}
              />
              <div className="flex flex-col gap-2 text-[0.7vw] mt-10">
                <div className="flex flex-row">
                  <div className="text-gray-400 w-[4vw]">Name:</div>
                  <div>{selectedUser && selectedUser.name}</div>
                </div>
                <div className="flex flex-row">
                  <div className="text-gray-400 w-[4vw]">EmailId:</div>
                  <div>{selectedUser && selectedUser.email}</div>
                </div>
                <div className="flex flex-row">
                  <div className="text-gray-400 w-[4vw]">Region:</div>
                  <div>{selectedUser && selectedUser.region}</div>
                </div>
                <div className="flex flex-row">
                  <div className="text-gray-400 w-[4vw]">Locale:</div>
                  <div>{selectedUser && selectedUser.locale}</div>
                </div>
                <div className="flex flex-row">
                  <div className="text-gray-400 w-[4vw]">Status:</div>
                  <div
                    className={`${
                      selectedUser && selectedUser.status == 1
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {selectedUser && selectedUser.status == 1
                      ? "Active"
                      : "Inactive"}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="text-gray-400 w-[4vw]">Last Login:</div>
                  <div>{selectedUser && selectedUser.lastLogin}</div>
                </div>
                {selectedUser && selectedUser.status == 1 ? (
                  <button
                    onClick={() => onUserStatusChange(0)}
                    className="px-1 rounded-[3px] w-[8vw] text-[0.6vw] m-auto bg-red-500/80 text-white font-semibold border-red-600 shadow-sm hover:shadow-xl active:scale-[0.9] duration-200"
                  >
                    <GppBadIcon />
                    Disable User
                  </button>
                ) : (
                  <button
                    onClick={() => onUserStatusChange(1)}
                    className="px-1 rounded-[3px] w-[8vw] text-[0.6vw] m-auto bg-green-500/80 text-white font-semibold border-green-600 shadow-sm hover:shadow-xl active:scale-[0.9] duration-200"
                  >
                    <VerifiedUserIcon />
                    Enable User
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="h-[50vh] p-2 border-r-[1px] w-full">
            <div className="text-gray-500 font-semibold text-[0.8vw] w-fit m-auto">
              <p>Roles</p>
            </div>
            <Divider sx={{ my: 1 }} />
            <button
              className="rounded-lg active:scale-[0.9] duration-200 mt-2 text-blue-500 underline text-[0.6vw]"
              onClick={() => selectedUser && setUserAction(true)}
            >
              Add New Role
            </button>
            <div className="p-2 overflow-y-auto h-[55vh] flex flex-col  mt-3 rounded-[5px]">
              {selectedUserRoles &&
                selectedUserRoles.map((role) => (
                  <div
                    className="mb-10 border-blue-300 w-fit shadow-lg p-2 rounded-lg bg-white"
                    key={role.id}
                  >
                    <div className="rounded-[8px] bg-white flex flex-col text-[0.7vw] w-[20vw]">
                      <p>
                        <span className="text-[0.6vw]">{role.name}</span>
                      </p>
                      <p>
                        <span className="text-[0.5vw] text-gray-400">
                          {role.description}
                        </span>
                      </p>
                    </div>
                    <p className="text-[0.5vw] mt-1">Permissions:</p>
                    <div className="relative ">
                      {(role &&
                        role.permissions.length > 0 &&
                        role.permissions.map((perm, idx) => (
                          <div className="flex flex-row relative" key={idx}>
                            <div className="h-[3vh] border-blue-300 border-[0.01vw] mt-auto mb-auto"></div>
                            <div className="h-[1px] w-[1vh] border-blue-300 border-[0.01vw] mt-auto "></div>
                            <div className="mt-auto rounded-sm flex flex-col text-[0.7vw]  rounded-t-[10px]  border-blue-300 text-blue-600">
                              <p>
                                <span className="text-[0.5vw]">
                                  {perm.name}
                                </span>
                                <span className="text-[0.5vw]">{": "}</span>
                                <span className="text-[0.5vw] text-red-400">
                                  {perm.description}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))) || (
                        <div className="flex flex-row" key={1}>
                          <div className="h-[4vh] w-[1px] border-blue-300 border-[0.01vw] mt-auto mb-auto"></div>
                          <div className="h-[1px] w-[2vh] border-blue-300 border-[0.01vw]  mt-auto"></div>
                          <div className="border-[2px] mt-auto rounded-sm flex flex-col text-[0.7vw]  rounded-t-[10px] px-5 bg-red-50 border-blue-300">
                            <p>
                              <span className="text-[0.5vw] text-red-500">
                                {"No Permission attached to this role"}
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => removeRoleHandler(role)}
                      className="px-1 rounded-[3px] text-[0.6vw] mt-2 bg-red-500 text-white font-semibold border-red-600 shadow-sm hover:shadow-xl active:scale-[0.9] duration-200"
                    >
                      <GppBadIcon />
                      Remove
                    </button>
                  </div>
                ))}
            </div>
          </div>
          {userAction && (
            <div className="flex flex-col bg-white m-auto border-2 rounded-lg p-2 border-blue-400">
              Available Roles
              <UpdateUserRoleForm
                availableRoles={allRoles.roleList}
                userRoleAssigHandler={userRoleAssigHandler}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
