export class RoleRequest {
  permissionList: Array<String> = [];
  roleName: String;
  description: String;
}

export class RolePermissionRequest {
  addPermissionList: Array<String> = [];
  removePermissionList: Array<String> = [];
  roleId: String;
}

export class UserRoleRequest {
  roleIds: Array<String> = [];
  userId: String;
}